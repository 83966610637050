<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table :headers="headers" :items="doctors" :items-per-page="-1" :search="searchDoctor"
                    :loading="loadingData" :expanded="expanded" single-expand show-expand item-key="id"
                    no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
                    class="elevation-1" hide-default-footer>
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1' : ''">
              M&eacute;dicos
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-switch v-model="filterPendings" class="pt-6" :loading="loadingData">
              <template v-slot:label>
                Pendientes({{ countPending }})
              </template>
            </v-switch>
            <v-spacer></v-spacer>
            <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
              <v-text-field id="findtext-doctor" label="Buscar.." name="finddoctor" outlined dense single-line clearable
                            background-color="white" hide-details v-model="searchDoctor"
                            class="white--text mt-0 pt-0 mr-2"
                            prepend-inner-icon="mdi-magnify"></v-text-field>
            </v-responsive>
            <v-btn color="blue darken-1" dark depressed elevation="0"
                   class="mb-2" @click="newItem" v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'">
                  Adicionar
            </v-btn>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  ¿Est&aacute; seguro que desea eliminar este elemento?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" :disabled="saveChanges" text @click="closeDelete">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" :loading="saveChanges" @click="deleteItemConfirm">
                    Aceptar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeletePicture" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  ¿Desea eliminar esta Imagen de la Galer&iacute;a?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" :disabled="saveChanges" text @click="closeDeletePicture">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" :loading="saveChanges" @click="deleteItemConfirmPicture">
                    Aceptar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDeleteQr" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  ¿Desea eliminar este QR?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" :disabled="saveChanges" text @click="closeDeleteQr">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" :loading="saveChanges" @click="deleteItemConfirmQr">
                    Aceptar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogError" persistent max-width="500">
              <v-card>
                <v-card-title class="text-h6 primary lighten-2">
                  <span class="headline">Error de Validaci&oacute;n</span>
                </v-card-title>

                <v-card-text>
                  <div class="text-h6 pa-12">{{ messageError }}!</div>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn text @click.stop="closeDialogError"> Cerrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialogGalleryPhotos" persistent width="90%">
              <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Administrador de Im&aacute;genes</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn class="hidden-sm-and-down" icon dark @click="dialogGalleryPhotos = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-container fill-height bottom-gradient fluid class="ma-0">
                  <v-row>
                    <v-col>
                      <div class="fill-height">
                        <v-slide-group center-active class="pa-0" next-icon="mdi-arrow-right-drop-circle-outline"
                                       prev-icon="mdi-arrow-left-drop-circle-outline" show-arrows>
                          <v-slide-item v-for="itemImage in editedItem.pictures" :key="itemImage.id"
                                        v-slot="{ active, toggle }">
                            <v-card :color="active ? undefined : 'white'" class="ma-4 rounded-lg" height="auto"
                                    width="250" elevation="5" @click="toggle">
                              <v-img :src="
                                  itemImage.url != null
                                    ? itemImage.url
                                    : require('@/assets/images/no-image.jpg')
                                " :lazy-src="
                                  require('@/assets/images/no-image.jpg')
                                " gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" aspect-ratio="1" height="250"
                                     class="rounded-lg white--text align-end justify-start text-end elevation-10">
                                <v-btn dark icon elevation="0" class="ma-2" v-on:click="deletePicture(itemImage)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>

                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-card>
                          </v-slide-item>
                        </v-slide-group>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <div class="container mt-0 pt-0">
                  <v-form v-model="validFile" lazy-validation>
                    <v-file-input v-model="selectedFiles" small-chips accept="image/png, image/jpeg, image/bmp"
                                  show-size multiple clearable label="Agregar ficheros" @change="inputChanged">
                      <template v-slot:selection="{ index, text }">
                        <v-chip small text-color="white" color="#295671" close @click:close="removeChip(index)">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                    <div v-if="imageFiles">
                      <h5>Archivos seleccionados</h5>
                      <v-chip v-for="(f, i) in imageFiles" :key="i" class="mr-1">
                        {{ f?.name }}
                      </v-chip>
                      <div class="py-3">
                        <v-btn dark :disabled="!validFile" color="primary" @click="uploadPictures()"
                               :loading="saveChanges">Actualizar
                          Galer&iacute;a
                        </v-btn>
                      </div>
                    </div>
                  </v-form>
                </div>
              </v-card>
            </v-dialog>

            <!-- qr -->

            <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialogQrCode" persistent width="90%">
              <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title class="text-subtitle-1 text-md-h5">Administrador de Qr</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn class="hidden-sm-and-down" icon dark @click="dialogQrCode = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-container fill-height bottom-gradient fluid class="ma-0">
                  <v-row>
                    <v-col col="6">
                      <div class="fill-height">
                        <!--v-model="model"-->
                        <v-slide-group center-active class="pa-0" next-icon="mdi-arrow-right-drop-circle-outline"
                                       prev-icon="mdi-arrow-left-drop-circle-outline" show-arrows>
                          <v-slide-item key="qr" v-slot="{ active, toggle }" v-if="editedItem.coepris_qr">
                            <v-card :color="active ? undefined : 'white'" class="ma-4 rounded-lg" height="auto"
                                    width="250" elevation="5" @click="toggle">
                              <v-img :src="
                                  editedItem.coepris_qr != null
                                    ? editedItem.coepris_qr
                                    : require('@/assets/images/no-image.jpg')
                                " :lazy-src="
                                  require('@/assets/images/no-image.jpg')
                                " gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" aspect-ratio="1" height="250"
                                     class="rounded-lg white--text align-end justify-start text-end elevation-10">
                                <v-btn dark icon elevation="0" class="ma-2"
                                       v-on:click="deleteQr(editedItem.coepris_qr)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>

                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-card>
                          </v-slide-item>
                        </v-slide-group>

                        <div v-if="editedItem.coepris_qr">
                          <h4>Link del qr</h4>

                          <v-text-field dense v-model="editedItem.coepris_qr_link"></v-text-field>
                        </div>
                      </div>
                    </v-col>

                    <v-col col="6" v-if="editedItem.coepris_qr">
                      <div>
                        <h4>Vencimiento del qr</h4>

                        <v-date-picker v-model="editedItem.vencimiento_fecha" locale="es" scrollable></v-date-picker>
                      </div>

                      <h3 class="mt-2">
                        {{ editedItem.vencimiento_fecha }}
                      </h3>
                    </v-col>
                  </v-row>

                  <v-col col="12" v-if="editedItem.coepris_qr">
                    <div style="display: flex; justify-content: center">
                      <v-btn @click="updateVencimientoFecha(editedItem)" :loading="saveChanges">
                        Guardar datos
                      </v-btn>
                    </div>
                  </v-col>
                </v-container>
                <div class="container mt-0 pt-0">
                  <v-file-input v-model="selectedFiles" small-chips accept="image/png, image/jpeg, image/bmp" show-size
                                multiple clearable label="Agregar ficheros" @change="inputChanged">
                    <template v-slot:selection="{ index, text }">
                      <v-chip small text-color="white" color="#295671" close @click:close="removeChip(index)">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <div v-if="imageFiles">
                    <h5>Archivos seleccionados</h5>
                    <v-chip v-for="(f, i) in imageFiles" :key="i" class="mr-1">
                      {{ f?.name }}
                    </v-chip>
                    <div class="py-3">
                      <v-btn dark color="primary" :loading="saveChanges" @click="uploadQr()">Actualizar Qr</v-btn>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="loadGalleryImages(item)">
                mdi-camera
              </v-icon>
            </template>
            <span>Administrar Im&aacute;genes</span>
          </v-tooltip>

          <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="loadQrCode(item)">
                mdi-qrcode
              </v-icon>
            </template>
            <span>Coepris QR</span>
          </v-tooltip>

          <FormDocumentsDoctor :item="item"/>

          <Keywords v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'" :entity="item" :url="urlServices" @reload="reload"/>

          <Certification v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'" :entity="item" :url="urlServicesCertification" :deleteUrl="deleteUrlServicesCertification"
                         @reload="reload"/>
                         
          <ExtraDocumentsDoctor v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'" :item="item"/>

          <HistorialChanges :item="item" :roles="user.roles | filterRoles"/>

          <Validator :doctor="item"/>

          <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles =='COPARMEX'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:item.specialties="{ item }">
          {{ speciality(item.specialties) }}
        </template>
        <template v-slot:item.city="{ item }">
          {{ item?.municipality?.name }}
        </template>
        <template v-slot:item.active="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.active))">
            {{ fixStateLabelExtra(item.active) }}
          </div>
        </template>
        <template v-slot:item.coparmex="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state_coparmex))">
            {{ fixStateLabelExtra(item.state_coparmex) }}
          </div>
        </template>
        <template v-slot:item.excellence="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state_ceetsb))">
            {{ fixStateLabelExtra(item.state_ceetsb) }}
          </div>
        </template>
        <template v-slot:item.repsabi="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state_coepris))">
            {{ fixStateLabelExtra(item.state_coepris) }}
          </div>
        </template>
        <template v-slot:item.outstanding="{ item }">
          <div class="custom-state-div" :style="fixStateColor(parseInt(item.promoted)===1?'Sí':'No')">
            {{ parseInt(item.promoted) === 1 ? 'Sí' : 'No' }}
          </div>
        </template>
        <template v-slot:item.premium="{ item }">
          <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.typeEntity, 'P'))">
            {{ fixStateLabelYesOrNot(item.typeEntity, 'P') }}
          </div>
        </template>
        <template v-slot:item.member="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.typeEntity, 'M'))">
            {{ fixStateLabelYesOrNot(item.typeEntity, 'M') }}
          </div>
        </template>
        <template v-slot:item.basic="{ item }">
          <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.typeEntity, 'B'))">
            {{ fixStateLabelYesOrNot(item.typeEntity, 'B') }}
          </div>
        </template>
        <template v-slot:item.count="{ item }">
          {{ item.historials_count }}
        </template>
        <template v-slot:no-data>
          <span class="text-h5">
            ¡No hay registros en el listado!</span>
          <br/>
          <v-btn color="primary" @click="reload"> Recargar</v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid>
              <v-row justify="start" class="ma-1">
                <h3>{{ item?.name }}</h3>
              </v-row>
              <v-row class="ma-1">
                <v-col cols="12" md="10" class="picture-list">
                  <v-row display="inline-block">
                    <v-img :src="
                        item.pictures[0] != null
                          ? item.pictures[0].url
                          : require('@/assets/images/no-image.jpg')
                      " :lazy-src="require('@/assets/images/no-image.jpg')" max-height="250" max-width="250"
                           aspect-ratio="1" class="white--text align-center justify-center elevation-2 picture-list">
                    </v-img>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="2"> Direcci&oacute;n:</v-col>
                    <v-col>
                      {{ item.address }}
                    </v-col>
                  </v-row>
                  <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="2"> Email:</v-col>
                    <v-col>
                      {{ item.email }}
                    </v-col>
                  </v-row>
                  <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="2"> Sitio Web:</v-col>
                    <v-col>
                      {{ item.web_page }}
                    </v-col>
                  </v-row>
                  <v-row :dense="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="5">
                      Tel&eacute;fono MX:
                      <a target="_blank" v-if="item.phone1!== 'null'" :href="'tel:+52' + item.phone1">+52
                        {{ item.phone1 | formatPhone }}</a>
                      <span v-else :href="'#'">No cuenta con número de teléfono mexicano</span>
                    </v-col>
                  </v-row>
                  <v-row :dense="$vuetify.breakpoint.smAndDown" justify="start">
                    <v-col cols="5">
                      Tel&eacute;fono USA:
                      <a target="_blank" v-if="item.phone2!== 'null'" :href="'tel:+1' + item.phone2">+1
                        {{ item.phone2 | formatPhone }}</a>
                      <span v-else :href="'#'">No cuenta con número de teléfono americano</span>
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>
              <v-row :dense="$vuetify.breakpoint.smAndDown">
                <v-col cols="4">
                  N&uacute;mero de Licencia Profesional:
                  {{ item.profesional_license_number }}
                </v-col>
                <v-col cols="4">
                  N&uacute;mero de Licencia de la Especialidad:
                  {{ item.specialty_license_number }}
                </v-col>
                <v-col cols="4">
                  N&uacute;mero de Certificaci&oacute;n V&aacute;lida:
                  {{ item.certification_validity }}
                </v-col>
                <v-col cols="4">
                  Certificado N&uacute;mero: {{ item.certificate_number }}
                </v-col>
                <v-col cols="4">
                  Verificaci&oacute;n de Licencia Profesional:
                  {{ item.professional_license_verification }}
                </v-col>
                <v-col cols="4">
                  Perfil en Facebook:
                  <a target="_blank" v-if="item.facebook_profile" :href="item.facebook_profile">Ir a la red social</a>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Cuenta de X:
                  <a target="_blank" v-if="item.twitter_profile" :href="item.twitter_profile">Ir a la red social</a>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Perfil en Instagram:
                  <a target="_blank" v-if="item.instagram_profile" :href="item.instagram_profile">Ir a la red social</a>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Canal de Youtube:
                  <div v-if="item.videos && item.videos.length > 0">
                    <a target="_blank" v-for="video in item.videos" :key="video.id" :href="video.url">Ir a la red social</a>
                  </div>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Perfil de Tiktok:
                  <a target="_blank" v-if="item.tiktok" :href="item.tiktok">Ir a la red social</a>
                  <span v-else :href="'#'">No cuenta con esta red social</span>
                </v-col>
                <v-col cols="4">
                  Horarios de Atenci&oacute;n: {{ item.servicehours }}
                </v-col>
              </v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
              <v-row class="mx-1"></v-row>
            </v-container>
          </td>
        </template>
        <template v-slot:footer="attrs">
          <Pagination v-bind="attrs" :reload="reloadData" url="pagination/doctors" :filterPendings="filterPendings" @loading-data="catchLoading"
            @load-data="catchDatas" @pendings-data="catchPendings"  :search="searchDoctor"/>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import doctorsService from "@/providers/DoctorsService";
import certificationsService from "@/providers/CertificationsService";
import Keywords from "@/components/keywords/Keywords";
import Certification from "@/components/certifications/Certification";
import {changeInformationSEO} from "@/providers/KeywordsServices";
import HistorialChanges from "@/components/historial-changes/HistorialChanges";
import ExtraDocumentsDoctor from "@/components/extra-documents-doctor/ExtraDocumentsDoctor";
import FormDocumentsDoctor from "@/components/form-documents-doctor/FormDocumentsDoctor";
import Pagination from "@/components/pagination/Pagination";
import Validator from "@/components/validator/Validator";

export default {
  name: "DoctorsModuleComponent",
  components: {
    HistorialChanges,
    Keywords,
    Certification,
    ExtraDocumentsDoctor,
    FormDocumentsDoctor,
    Pagination,
    Validator
  },
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
  },
  data: () => ({
    focus: false,
    snackBar: false,
    snackText: "",
    valid: false,
    searchDoctor: "",
    loadingData: false,
    dialogDelete: false,
    dialogError: false,
    dialogDeletePicture: false,
    dialogGalleryPhotos: false,
    currentPicture: undefined,
    selectedFiles: [],
    imageFiles: [],
    messageError: "",
    associationsList: [],
    certificationsList: [],
    specialtiesList: [],
    doctors: [],
    cities: [],
    validFile: false,
    expanded: [],
    user: {},
    headers: [
      {text: "", value: "data-table-expand", width: 5},
      {text: "Id", value: "id", align: " d-none"},
      {
        text: "Nombre",
        align: "start",
        value: "name",
        width: 260,
      },
      {
        text: "Especialidad",
        align: "start",
        value: "specialties",
        width: 170,
      },
      {
        text: "Municipio",
        align: "start",
        value: "city",
        width: 140,
      },
      {
        text: "E-mail",
        value: "email"
      },
      {
        text: "Activo",
        value: "active",
        width: 100,
      },
      {
        text: "Coparmex",
        value: "coparmex",
        width: 120,
      },
      {
        text: "Excelencia",
        value: "excellence",
        width: 118,
      },
      {
        text: "Repssabi",
        value: "repsabi",
        width: 120,
      },
      {
        text: "Destacado",
        value: "outstanding",
        width: 118,
      },
      {
        text: "Premium",
        value: "premium",
        width: 110,
      },
      {
        text: "Miembro",
        value: "member",
        width: 110,
      },
      {
        text: "Básico",
        value: "basic",
        width: 110,
      },
      {
        text: "Visitas",
        value: "count",
        width: 100,
      },
      {text: "Acciones", value: "actions", width: 105, sortable: false},
    ],
    editedIndex: -1,
    countPending: 0,
    editedItem: {},
    saveChanges: false,
    reloadData: false,

    filterPendings: false,

    dialogDeleteQr: false,
    dialogQrCode: false,

    imageRules: [
        (value) =>
            !value ||
            value.size < 2000000 ||
            "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
      ],
  }),
  filters: {
    filterRoles(value){
      switch (value) {
        case "ADMIN":
          return ["COPARMEX", "REPSSABI", "CEETSB"];
        case "COPARMEX":
          return ["COPARMEX", "REPSSABI", "CEETSB"];
        case "COEPRIS":
          return ["REPSSABI"];
        case "CEETSB":
          return ["CEETSB"];
        default:
          return [];
      }
    },
    formatPhone(value) {
      if (value === null || value == "undefined") {
        return "";
      }
      return (
          "(" +
          value.substring(0, 3) +
          ") " +
          value.substring(3, 6) +
          "-" +
          value.substring(6)
      );
    },
  },
  computed: {
    deleteUrlServicesCertification() {
      return `${process.env.VUE_APP_BHC_BASE_URL}/delete-certification/doctors`;
    },
    urlServicesCertification() {
      return `${process.env.VUE_APP_BHC_BASE_URL}/add-certification/doctors`;
    },
    urlServices() {
      return `${process.env.VUE_APP_BHC_BASE_URL}/keywords/doctors`;
    }
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDeletePicture(val) {
      val || this.closeDeletePicture();
    },
    dialogDeleteQr(val) {
      val || this.closeDeleteQr();
    },
  },
  methods: {
    reload(){
      this.reloadData = !this.reloadData;
    },
    catchDatas(value){
      this.doctors = value.map(e => {
          return {
            ...e,
            email: e.email && e.email != null && e.email != "null" ? e.email : "",
            web_page: e.web_page && e.web_page != null && e.web_page != "null" ? e.web_page : "",
            facebook_profile: e.facebook_profile && e.facebook_profile != null && e.facebook_profile != "null" ? e.facebook_profile : "",
            instagram_profile: e.instagram_profile && e.instagram_profile != null && e.instagram_profile != "null" ? e.instagram_profile : "",
            youtube_video: e.youtube_video && e.youtube_video != null && e.youtube_video != "null" ? e.youtube_video : "",
            twitter_profile: e.twitter_profile && e.twitter_profile != null && e.twitter_profile != "null" ? e.twitter_profile : "",
          }
        });
    },
    catchLoading(value){
      this.loadingData = value
    },
    catchPendings(value){
      this.countPending = value;
    },
    fixStateColor(state) {
      switch (state) {
        case 'Rechazado':
          return "background-color: #CA2B2BFF;";
        case 'Pendiente':
          return "background-color: #6E87DBFF;";
        case 'Aprobado':
          return "background-color: #6bc263;";
        case 'Si':
          return "background-color: #6bc263;";
        case 'Sí':
          return "background-color: #6bc263;";  
        case 'No':
          return "background-color: #cf7336;";
        default:
          return "background-color: #000;";
      }
    },
    fixStateLabel(item) {
      switch (this.user.roles) {
        case 'CEETSB':
          return item.state_ceetsb;
        case 'COEPRIS':
          return item.state_coepris;
        case 'COPARMEX':
          return item.state_coparmex;
        case 'USER':
          return item.state_coparmex;
        default:
          return item.state;
      }
    },
    fixStateLabelExtra(state) {
      switch (state !== "") {
        case state !== "":
          if (state === 0 || state === "Pendiente") {
            return "Pendiente";
          } else if (state === 1 || state === "Aprobado") {
            return "Aprobado";
          } else {
            return "Rechazado";
          }
      }
    },
    fixStateLabelYesOrNot(state, label) {
      switch (state) {
        case label:
          return "Sí";
        default:
          return "No"
      }
    },
    speciality(specialities) {
      let out = "";
      specialities.forEach(e => {
        out = `${out}${e?.name}, `;
      })
      return out.substring(0, out.length - 2);
    },

    isFechaVencida(fecha) {
      const fechaVencimiento = fecha.split("T")[0];
      const fechaActual = new Date().toISOString().split("T")[0];
      return fechaVencimiento > fechaActual;
    },
    isFechaCercanaTreintaDias(fecha) {
      const fechaVencimiento = new Date(fecha);
      const fechaActual = new Date();
      const diferenciaEnMilisegundos = fechaVencimiento - fechaActual;
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      return diferenciaEnDias == 30;
    },
    isFechaCercanaQuinceDias(fecha) {
      const fechaVencimiento = new Date(fecha);
      const fechaActual = new Date();
      const diferenciaEnMilisegundos = fechaVencimiento - fechaActual;
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      return diferenciaEnDias == 15;
    },
    enviarNotificacionCorreo(doctor_id, send_emails) {
      doctorsService.sendManyEmail(doctor_id, send_emails).then((data) => {
        if (data.success) {
          console.log("Correo enviado: ", data);
        } else {
          console.log("Error enviando correo: ", data);
        }
      });
    },
    newItem() {
      this.$router.push({
        name: "AddDoctor"
      })
    },
    editItem(item) {
      this.$router.push({
        name: "UpdateDoctor",
        params: {
          id: item.id
        }
      });
    },
    deleteItem(item) {
      this.editedIndex = this.doctors.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.vencimiento_fecha = this.formatDate(
          this.editedItem.vencimiento_fecha
      );
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.saveChanges = true;
      doctorsService.deleteRecord(this.editedItem.id).then((response) => {
        if (response.success) {
          this.doctors.splice(this.editedIndex, 1);
          this.snackText = "Registro eliminado con éxito.";
          this.reload();
          this.closeDelete();
        } else {
          this.snackText = "Un error impidió eliminar el registro!";
        }
      }).catch((err) => {
        console.log(err);
        this.snackText = "Un error impidió eliminar el registro!";
      }).finally(() => {
        this.snackBar = true;
        this.saveChanges = false;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    /* Para administrar las imagenes */
    loadGalleryImages(item) {
      this.selectedFiles = [];
      this.imageFiles = [];
      doctorsService.getRecord(item.id).then((fetch_data) => {
        this.editedItem = Object.assign({}, fetch_data.value);
        this.editedItem.vencimiento_fecha = this.formatDate(
            this.editedItem.vencimiento_fecha
        );
      });
      if (!this.dialogGalleryPhotos) this.dialogGalleryPhotos = true;
    },

    loadQrCode(item) {
      this.selectedFiles = [];
      this.imageFiles = [];
      doctorsService.getRecord(item.id).then((fetch_data) => {
        this.editedItem = Object.assign({}, fetch_data.value);
        this.editedItem.vencimiento_fecha = this.formatDate(
            this.editedItem.vencimiento_fecha
        );
      });
      if (!this.dialogQrCode) this.dialogQrCode = true;
    },

    deleteQr(item) {
      this.currentPicture = item;
      this.dialogDeleteQr = true;
    },

    closeDeleteQr() {
      this.dialogDeleteQr = false;
    },

    deleteItemConfirmQr() {
      this.saveChanges = true;
      doctorsService
          .deleteQr(this.editedItem.id, this.currentPicture)
          .then((result) => {
            this.closeDeleteQr();
            if (result.success) {
              this.snackText = "Qr eliminado con éxito!";
              this.loadQrCode(this.editedItem);
            } else {
              this.snackText = "Un error impidió eliminar el Qr!";
            }
          }).catch(err => {
        console.log(err);
        this.snackText = "Un error impidió eliminar el Qr!";
      }).finally(() => {
        this.snackBar = true;
        this.saveChanges = false;
      });
    },

    uploadQr() {
      for (let i = 0; i < this.imageFiles.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFiles[i]);
        this.saveChanges = true;
        doctorsService
            .uploadQr(this.editedItem.id, -1, this.imageFiles[i])
            .then((result) => {
              if (result.success) {
                this.loadQrCode(this.editedItem);
                this.snackText = "Qr subido al servidor!";
              } else {
                this.snackText = "Un error impidió subir el qr al servidor!";
              }
            }).catch(err => {
          console.log(err);
          this.snackText = "Un error impidió subir el qr al servidor!";
        }).finally(() => {
          this.snackBar = true;
          this.saveChanges = false;
        });
      }
    },

    async updateVencimientoFecha(doctor) {
      try {
        this.saveChanges = true;
        doctorsService.updateRecord({
          ...doctor,
          province: doctor.city ? doctor.city.id : null,
          municipality: doctor.municipality ? doctor.municipality.id : null,
        }).then((response) => {
          if (response) {
            this.reload();
            this.dialogQrCode = false;
            this.snackText = "Fecha de vencimiento de coepris actualizado";
            return response;
          } else {
            this.snackText = "Ha ocurrido un error";
            return null;
          }
        }).catch(err => {
          console.log(err);
          this.snackText = "Ha ocurrido un error";
        }).finally(() => {
          this.snackBar = true;
          this.saveChanges = false;
        });
      } catch (error) {
        console.log(error);
      }
    },

    formatDate(itemDate) {
      if (itemDate) {
        const date = new Date(itemDate);
        return date.toISOString().split("T")[0];
      }
      return null;
    },

    //////////////////////////////////////////////////

    removeChip(index) {
      this.imageFiles.splice(index, 1);
      this.selectedFiles = [...this.imageFiles];
    },
    inputChanged() {
      this.imageFiles = [...this.selectedFiles];
    },
    uploadPictures() {
      for (let i = 0; i < this.imageFiles.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFiles[i]);
        this.saveChanges = true;
        doctorsService
            .uploadImage(this.editedItem.id, -1, this.imageFiles[i])
            .then((result) => {
              if (result.success) {
                this.loadGalleryImages(this.editedItem);
                this.snackText = "Imágen subida al servidor. Galería actualizada!";
              } else {
                this.snackText = "Un error impidió subir al menos una imágen al servidor!";
              }
            }).catch((err) => {
          console.log(err);
          this.snackText = "Un error impidió subir al menos una imágen al servidor!";
        }).finally(() => {
          this.snackBar = true;
          this.saveChanges = false;
        });
      }
    },
    deletePicture(item) {
      this.currentPicture = Object.assign({}, item);
      this.dialogDeletePicture = true;
    },
    deleteItemConfirmPicture() {
      this.saveChanges = true;
      doctorsService
          .deleteImage(
              this.currentPicture.pivot.doctor_id,
              this.currentPicture.id
          )
          .then((result) => {
            this.closeDeletePicture();
            if (result.success) {
              this.snackText = "Imagen eliminada desde la galería!";
              this.loadGalleryImages(this.editedItem);
            } else {
              this.snackText = "Un error impidió eliminar la imágen desde la galería!";
            }
          }).catch((err) => {
        console.log(err);
        this.snackText = "Un error impidió eliminar la imágen desde la galería!";
      }).finally(() => {
        this.snackBar = true;
        this.saveChanges = false;
      });
    },
    closeDeletePicture() {
      this.dialogDeletePicture = false;
    },
    changePreviewImage() {
      if (this.imageFile) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFile);
        if (this.editedItem.pictures.length === 0) {
          let imagePicture = {
            id: 2,
            module_id: 2,
            url: this.imageFile?.name,
          };
          this.editedItem.pictures.push(imagePicture);
        }
      }
    },
    closeDialogError() {
      this.dialogError = false;
      this.messageError = "";
    },
  },
  mounted() {
    this.user = this.$store.getters.getUser;
    certificationsService.getViewList().then((record) => {
      this.certificationsList = record.value;
    });
    changeInformationSEO();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}

.custom-state-div {
  padding: 10px;
  color: #fff;
  border-radius: 20px;
  font-weight: 600;
  text-align: center;
  width: max-content;
  justify-self: center;
}

.picture-list {
  border-radius: 10px;
  max-width: 250px;
}
</style>
