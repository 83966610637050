<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
                mdi-file-export
            </v-icon>
        </template>

        <span>Carga de documentos</span>

        <v-dialog v-model="dialog" fullscreen persistent>
            <v-card>
                <div>
                    <v-card-title class="text-h6 grey lighten-2">
                        <span class="headline">Carga de documentos</span>
                        <v-btn plain icon @click="close" class="ml-auto mr-0">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col lg="8" md="8" sm="12">
                            <v-form lazy-validation ref="form" v-model="validForm">
                                <v-card-text class="pt-2 pl-2 pr-2">
                                    <v-row>
                                        <v-col cols="12" class="cols-custom">
                                            <div class="d-block">
                                                <label class="title d-block ">
                                                    <strong>Identificaci&oacute;n oficial del solicitante</strong>
                                                </label>
                                                <label class="subtitle-1 d-block">Puede presentar cualquiera de las
                                                    siguientes:</label>
                                                <ul>
                                                    <li class="subtitle-1">Credencial para votar vigente, expedida por
                                                        el Instituto Nacional Electoral (antes Instituto Federal
                                                        Electoral).</li>
                                                    <li class="subtitle-1">Pasaporte vigente.</li>
                                                    <li class="subtitle-1">C&eacute;dula profesional vigente con
                                                        fotograf&iacute;a. Quedan exceptuadas las c&eacute;dulas
                                                        profesionales electr&oacute;nicas.</li>
                                                </ul>
                                                <label class="subtitle-2">
                                                    <strong>En caso de las fotocopias o archivos digitalizados de
                                                        identificaciones oficiales (ej. credencial de elector,
                                                        c&eacute;dula, otros) se deber&aacute; incluir en una sola cara
                                                        de la copia ambos lados del documento original.</strong>
                                                </label>
                                            </div>
                                            <v-file-input accept="image/png, image/jpeg, .pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" prepend-icon="mdi-camera" small-chips
                                                label="Identificación por la parte de adelante"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="identity">
                                            </v-file-input>
                                            <v-file-input
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" prepend-icon="mdi-camera-flip-outline"
                                                label="Identificación por la parte de atrás"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="identity2">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div class="d-block">
                                                <label class="title d-block ">
                                                    <strong>Aviso de Funcionamiento</strong>
                                                </label>
                                                <label class="subtitle-1 d-block">Cargue aqu&iacute; copia de su Aviso
                                                    de Funcionamiento en formato PDF. Si no cuenta con un Aviso de
                                                    Funcionamiento deber&aacute; de tramitarlo a trav&eacute;s de la
                                                    secci&oacute;n de
                                                    tr&aacute;mites en l&iacute;nea de la COFEPRIS o acudir a las
                                                    oficinas de la
                                                    COEPRIS que corresponde a su domicilio. </label>
                                            </div>
                                            <v-file-input accept=".pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="functionality_alert">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div class="d-block">
                                                <label class="title d-block ">
                                                    <strong>T&iacute;tulo y c&eacute;dula profesional del solicitante en
                                                        formato
                                                        PDF</strong>
                                                </label>
                                                <label class="subtitle-1 d-block">Documentaci&oacute;n que acredita y
                                                    avala la
                                                    formaci&oacute;n acad&eacute;mica y t&eacute;cnica de un profesional
                                                    de la medicina en
                                                    M&eacute;xico, garantizando un alto nivel de calidad y seguridad en
                                                    la
                                                    atenci&oacute;n m&eacute;dica que se brinda a los pacientes.</label>
                                            </div>
                                            <v-file-input accept=".pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips
                                                prepend-icon="mdi-clipboard-account"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="cedula">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div class="d-block">
                                                <label class="title d-block ">
                                                    <strong>C&eacute;dula de especialidad m&eacute;dica del solicitante
                                                        en formato
                                                        PDF</strong>
                                                </label>
                                            </div>
                                            <v-file-input accept=".pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips prepend-icon="mdi-doctor"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="specialty_cedula">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div class="d-block">
                                                <label class="title d-block ">
                                                    <strong>Constancia de la certificaci&oacute;n de su especialidad,
                                                        emitida
                                                        por el Comit&eacute; Normativo Nacional de Consejos de
                                                        Especialidades
                                                        M&eacute;dicas (CONACEM).</strong>
                                                </label>
                                                <label class="subtitle-1 d-block">CONACEM: Acreditaci&oacute;n mexicana
                                                    que
                                                    garantiza a la sociedad que la certificaci&oacute;n de los
                                                    m&eacute;dicos
                                                    especialistas es la adecuada. Siendo el Comit&eacute; Normativo
                                                    Nacional de
                                                    Consejos de Especialidades M&eacute;dicas (Conacem) la autoridad
                                                    encargada
                                                    de regular y certificar a las 47 especialidades m&eacute;dicas y 76
                                                    subespecialidades. En caso de contar con varias especialidades o
                                                    subespecialidades</label>
                                            </div>
                                            <v-file-input
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips
                                                prepend-icon="mdi-file-document"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="specialty_conacem">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div class="d-block">
                                                <label class="title d-block ">
                                                    <strong>Fotograf&iacute;a a color del m&eacute;dico
                                                        especialista</strong>
                                                </label>
                                                <label class="subtitle-1 d-block">Fotograf&iacute;a a color para
                                                    integrar al
                                                    Cat&aacute;logo Estatal de Excelencia de Turismo de Salud y
                                                    Bienestar de
                                                    Baja California.</label>
                                                <label class="subtitle-1 d-block">La fotograf&iacute;a debe ser a color
                                                    y de
                                                    buena calidad. De preferencia portar bata blanca. En formato JPG o
                                                    PNG y
                                                    no exceder el tamaño de 2MB.</label>
                                            </div>
                                            <v-file-input accept="image/png, image/jpeg"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips prepend-icon="mdi-image"
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                truncate-length="50" v-model="photo">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div class="d-block">
                                                <label class="title d-block ">
                                                    <strong>Constancia(s) de su acreditaci&oacute;n internacional de su
                                                        especialidad en un solo formato PDF</strong>
                                                </label>
                                            </div>
                                            <v-file-input accept=".pdf"
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size :loading="loading" small-chips
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                prepend-icon="mdi-account-check" label="" truncate-length="50"
                                                v-model="international_specialty">
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="cols-custom">
                                            <div class="d-block">
                                                <label class="title d-block ">
                                                    <strong>Constancia del Registro Estatal de Establecimientos
                                                        Prestadores de Servicios de Salud y Bienestar (REPSSABI) de la
                                                        COEPRIS</strong>
                                                </label>
                                                <label class="subtitle-1 d-block">Constancia del hospital o
                                                    cl&iacute;nica en
                                                    donde brinda las consultas. (Si aplica)</label>
                                                <label class="subtitle-1 d-block">Si no cuenta con su constancia del
                                                    REPSSABI deber&aacute; de tramitarlo a trav&eacute;s de la
                                                    secci&oacute;n de tr&aacute;mites en
                                                    l&iacute;nea de la COFEPRIS o acudir a las oficinas de la COEPRIS
                                                    que
                                                    corresponde a su domicilio.
                                                    <a target="_blank"
                                                        href="https://tramites.bajacalifornia.gob.mx/baja_california_39446916/repssabi-_461539f6-bd94-4ca6-963a-bdde8bdad5bd">https://tramites.bajacalifornia.gob.mx/baja_california_39446916/repssabi-_461539f6-bd94-4ca6-963a-bdde8bdad5bd</a>
                                                </label>
                                            </div>
                                            <v-file-input
                                                :disabled="saveChanges || loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')"
                                                show-size
                                                :rules="[v => (!v || v?.size < 2097152) || 'Tamaño máximo 2mb']"
                                                :loading="loading" small-chips prepend-icon="mdi-registered-trademark"
                                                truncate-length="50" v-model="coepris_repssabi">
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="d-flex">
                                    <v-btn class="mr-2 ml-auto" color="info" @click="save" :loading="saveChanges"
                                        :disabled="loading || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX') || !valid || !validForm">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-col>
                        <v-col lg="4" md="4" sm="12">
                            <v-card>
                                <v-card-title>Documentos subidos</v-card-title>
                                <v-card-text>
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.identity"
                                        :name="itemCurrent.identity_name" :url="itemCurrent.identity" icon="mdi-camera"
                                        field="identity" table="doctors" :id="itemCurrent.id" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.identity2"
                                        :name="itemCurrent.identity2_name" :url="itemCurrent.identity2"
                                        icon="mdi-camera-flip-outline" field="identity2" table="doctors"
                                        :id="itemCurrent.id" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.functionality_alert"
                                        :name="itemCurrent.functionality_alert_name"
                                        :url="itemCurrent.functionality_alert" icon="mdi-paperclip"
                                        field="functionality_alert" table="doctors" :id="itemCurrent.id" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.cedula"
                                        :name="itemCurrent.cedula_name" :url="itemCurrent.cedula"
                                        icon="mdi-clipboard-account" field="cedula" table="doctors"
                                        :id="itemCurrent.id" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.specialty_cedula"
                                        :name="itemCurrent.specialty_cedula_name" :url="itemCurrent.specialty_cedula"
                                        icon="mdi-doctor" field="specialty_cedula" table="doctors"
                                        :id="itemCurrent.id" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.specialty_conacem"
                                        :name="itemCurrent.specialty_conacem_name" :url="itemCurrent.specialty_conacem"
                                        field="specialty_conacem" table="doctors" :id="itemCurrent.id" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.photo"
                                        :name="itemCurrent.photo_name" :url="itemCurrent.photo" icon="mdi-image"
                                        field="photo" table="doctors" :id="itemCurrent.id" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.international_specialty"
                                        :name="itemCurrent.international_specialty_name"
                                        :url="itemCurrent.international_specialty" icon="mdi-account-check"
                                        field="international_specialty" table="doctors" :id="itemCurrent.id" />
                                    <ItemViewDocument @reload="loadData" v-if="itemCurrent.coepris_repssabi"
                                        :name="itemCurrent.coepris_repssabi_name" :url="itemCurrent.coepris_repssabi"
                                        icon="mdi-registered-trademark" field="coepris_repssabi" table="doctors"
                                        :id="itemCurrent.id" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>
    </v-tooltip>
</template>
<script>
import doctorServices from "@/providers/DoctorsService";
import ItemViewDocument from "@/components/form-documents-doctor/ItemViewDocument";

export default {
    name: "FormDocumentsDoctor",
    props: ["item"],
    components: {
        ItemViewDocument
    },
    data(){
        return {
            dialog: false,
            loading: false,
            saveChanges: false,
            validForm: false,
            identity: null,
            identity2: null,
            itemCurrent: {},
            functionality_alert: null,
            cedula: null,
            specialty_cedula: null,
            specialty_conacem: null,
            photo: null,
            international_specialty: null,
            coepris_repssabi: null,
            user: {},
        }
    },
    computed: {
        valid(){
            return this.identity || this.functionality_alert || this.cedula || this.specialty_cedula || this.specialty_conacem || this.photo || this.international_specialty || this.coepris_repssabi || this.identity2;
        }
    },
    methods: {
        loadData(){
            this.loading = true;
            doctorServices.getRecord(this.item.id).then(resp=>{
                this.itemCurrent = resp.value;
            }).catch(()=>{
                this.snackText = "Ha ocurrido un error!";
                this.snackBar = true;
            }).finally(()=>{
                this.loading = false;
            })
        },
        openDialog(){
            this.dialog = true;
            this.loadData();
        },
        close(){
            this.dialog = false;
            this.resetForm();
        },
        resetForm(){
            this.functionality_alert = null;
            this.cedula = null;
            this.specialty_cedula = null;
            this.specialty_conacem = null;
            this.photo = null;
            this.international_specialty = null;
            this.coepris_repssabi = null;
            this.identity = null;
            this.identity2 = null;
            this.$refs.form.reset();
        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.saveChanges = true;
                doctorServices.uploadDocumentForm(this.item.id, this.identity, this.functionality_alert, this.cedula,
                    this.specialty_cedula, this.specialty_conacem, this.photo,
                    this.international_specialty, this.coepris_repssabi, this.identity2)
                    .then(resp => {
                        if (resp.data.success) {
                            this.resetForm();
                            this.snackText = "Documento guardado con éxito";
                            this.loadData();
                        }
                        else {
                            this.snackText = "Ha ocurrido un error!";
                        }
                    }).catch(() => {
                        this.snackText = "Ha ocurrido un error!";
                    }).finally(() => {
                        this.saveChanges = false;
                        this.snackBar = true;
                    });
            }
        }
    },
    mounted(){
        this.user = this.$store.getters.getUser;
    }
}
</script>
<style>
.cols-custom {
    border: 1px #dcdbdb solid;
    border-radius: 0.25rem !important;
    margin: 0.4rem;
    box-shadow: 4px 4px 4px #e3e2e2;
}
</style>
