<template>
    <v-col cols="12">
        <v-row>
            <v-col cols="1">
                <v-avatar size="36px"
                    v-if="item && item.certification && item.certification.image_certification != null">
                    <v-img :src="item.certification.image_certification"
                        :lazy-src="item.certification.image_certification" />
                </v-avatar>
                <v-icon v-else size="36px">mdi-certificate-outline</v-icon>
            </v-col>
            <v-col cols="3" class="d-flex">
                <label>{{ item?.certification?.name }}</label>
            </v-col>
            <v-col cols="2">{{ item.expire | formatDate }}</v-col>
            <v-col cols="2">{{ item.created_at | formatDate }}</v-col>
            <v-col cols="2">{{ item | changeCreated }}</v-col>
            <v-col cols="2">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" v-on:click="openDialog">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" v-on:click="goToLink(item.url)">
                            mdi-file-document
                        </v-icon>
                    </template>
                    <span>Descargar</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" v-on:click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Eliminar</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" width="350px">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title class="text-subtitle-1 text-md-h5">Editar
                            certificaci&oacute;n</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn class="hidden-sm-and-down" icon dark @click="resetData">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-container fill-height bottom-gradient fluid class="ma-0">
                        <v-row>
                            <v-col>
                                <v-text-field type="date" dense v-model="expire" class="mt-2"
                                    :rules="[v => !!v || 'Campo es requerido']" :disabled="loading"
                                    label="Fecha de expiración"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :loading="loading" :disabled="!valid" color="primary" @click="editItem">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-col>
</template>
<script>
import doctorsService from "@/providers/DoctorsService";
import axios from "axios";
import moment from "moment";

export default {
    name: "ItemCertificationDoctor",
    props: ["item", "deleteUrl"],
    data(){
        return {
            dialog: false,
            expire: "",
            loading: false,
            valid: false,
        }
    },
    filters: {
        changeCreated(value) {
            let user = value.user;
            return user ? user.email : "";
        },
        formatDate(fecha) {
            moment.locale("es");
            return moment(fecha).format("DD/MM/YYYY");
        },
    },
    methods: {
        goToLink(link) {
            window.open(link, '_blank');
        },
        deleteItem(item) {
            axios.post(this.deleteUrl, {
                id: item.doctor_id,
                certification: item.certification.id
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
                    }
                }
            ).then(resp => {
                if (resp.status === 200) {
                    this.snackText = "Certificación eliminada con éxito";
                    this.$emit('reload');
                }
                else {
                    this.snackText = 'Ha ocurrido un error';
                }
            }).catch(() => {
                this.snackText = 'Ha ocurrido un error';
            }).finally(() => {
                this.snackBar = true;
            })
        },
        openDialog(){
            this.expire = this.item.expire;
            this.dialog = true;
        },
        resetData(){
            this.expire = "";
            this.dialog = false;
        },
        editItem(){
            this.loading = true;
            doctorsService.updateCertification({
                id: this.item.doctor_id,
                certification: this.item.certification.id,
                expire: this.expire
            }).then(resp=>{
                if(resp.data.success){
                    this.resetData();
                    this.$emit('reload');
                }
            }).catch(err=>console.log(err)).finally(()=>{
                this.loading = false;
            })
        }
    }
}
</script>
